import React, { useState, useEffect, useRef } from "react";
import fireDb from "../firebase";
import { toast } from "react-toastify";

const initialState = {
  cohort1: "",
  cohort2: "",
  cohort3: "",
  cohort4: "",
  cohort5: "",
  cohort6: "",
  cohort7: "",
  cohort8: ""
};

const Options = () => {

  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const { cohort1, cohort2, cohort3, cohort4, cohort5, cohort6, cohort7, cohort8 } = state;

  var id = "main";

  useEffect(() => {
    fireDb.child("settings").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, []);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });    
    } else {
      setState({ ...initialState });      
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
          fireDb.child("settings").child("main").set(state);
          toast.success("Settings Updated Successfully");
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <h4>Options</h4>
      <form
        onSubmit={handleSubmit}
      >

        <div className = "row">
          <div className = "col-md-12">
            <p>Here you can update the cohorts for the submission form.</p>
          </div>
        </div>
        
        <div className = "row">
            <div className = "col-md-6">
              <div className = "form-area">
                <label htmlFor="cohort1">Cohort 1</label>
                <input
                  type="text"
                  id="cohort1"
                  name="cohort1"
                  placeholder="Insert Cohort"
                  value={cohort1 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="cohort2">Cohort 2</label>
                <input
                  type="text"
                  id="cohort2"
                  name="cohort2"
                  placeholder="Insert Cohort"
                  value={cohort2 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="cohort3">Cohort 3</label>
                <input
                  type="text"
                  id="cohort3"
                  name="cohort3"
                  placeholder="Insert Cohort"
                  value={cohort3 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="cohort4">Cohort 4</label>
                <input
                  type="text"
                  id="cohort4"
                  name="cohort4"
                  placeholder="Insert Cohort"
                  value={cohort4 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="cohort5">Cohort 5</label>
                <input
                  type="text"
                  id="cohort5"
                  name="cohort5"
                  placeholder="Insert Cohort"
                  value={cohort5 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="cohort6">Cohort 6</label>
                <input
                  type="text"
                  id="cohort6"
                  name="cohort6"
                  placeholder="Insert Cohort"
                  value={cohort6 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="cohort7">Cohort 7</label>
                <input
                  type="text"
                  id="cohort7"
                  name="cohort7"
                  placeholder="Insert Cohort"
                  value={cohort7 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="cohort8">Cohort 8</label>
                <input
                  type="text"
                  id="cohort8"
                  name="cohort8"
                  placeholder="Insert Cohort"
                  value={cohort8 || ""}
                  onChange={handleInputChange}
                />
              </div>
              <input type="submit" value={"Update"} />
          </div>
      </div>

      </form>
    </div>
  );
};

export default Options;
