import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
import Options from "./pages/Options";
import Approved from "./pages/Approved";
import InReview from "./pages/InReview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Login from "./pages/Login";
import { auth } from "./firebase";


/**
 * [RequireAuth Create secure route]
 * @param {@View} ComposedComponent [Component name to be pass here]
 */
 function RequireAuth(ComposedComponent) {

  class RequireAuth extends Component {  
    state = {
      isAuthenticated: (auth.currentUser !== null ? true : false)
    }

    // Push to login route if not authenticated on mount
    async componentDidMount() {        
      if(!this.state.isAuthenticated) {
          await this.props.history.push("/login");
      }
    }

    // Push to login route if not authenticated on update
    componentDidUpdate(nextProps) {
      if(!this.state.isAuthenticated) {
          this.props.history.push("/login");
      }        
    }

    // Otherwise render the original component
    render() {
      return <ComposedComponent {...this.props}/>
    }  
  }
  return RequireAuth;  
}

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <ToastContainer position="top-center" />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/approved" component={RequireAuth(Approved)} />
          <Route exact path="/review" component={RequireAuth(InReview)} />
          <Route path="/options" component={RequireAuth(Options)} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
