import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Header.css";
import { auth } from "../firebase";

const Header = () => {
  const [activeTab, setActiveTab] = useState("Login");
  const [isAuth, setAuth] = useState(false);
  const location = useLocation();
  const [search, setSearch] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/login") {
      setActiveTab("Login");
    } else if (location.pathname === "/home") {
      setActiveTab("Home");
    }   
    
    if(auth.currentUser !== null){
      setAuth(true);
    }
  }, [location]);

  const logout = () => {
    auth.signOut().then(() => {
      setAuth(false);
      history.push("/login")
    }).catch((error) => {
      console.log(error);
    });  
  };

  return (
    <div className="header">
      <p className="logo">Photography Map</p>
      <div className="header-right">
        {isAuth ? 
        <>
          <Link to="/approved">
            <p
              className={`${activeTab === "Approved" ? "active" : ""}`}
              onClick={() => setActiveTab("Approved")}
            >
              Approved
            </p>
          </Link>
          <Link to="/review">
            <p
              className={`${activeTab === "In Review" ? "active" : ""}`}
              onClick={() => setActiveTab("In Review")}
            >
              In Review
            </p>
          </Link>
          <Link to="/options">
            <p
              className={`${activeTab === "Options" ? "active" : ""}`}
              onClick={() => setActiveTab("Options")}
            >
              Form Options
            </p>
          </Link>
            <p
              className={`${activeTab === "Logout" ? "active" : ""}`}
              onClick={() => logout()}
            >
              Logout
            </p>
        </>
          :
          null
        }

      </div>
    </div>
  );
};

export default Header;
