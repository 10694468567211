import React, { useState, useEffect } from "react";
import fireDb from "../firebase";
import "./Approved.css";
import { toast } from "react-toastify";

const InReview = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    fireDb.child("messages").orderByChild("status").equalTo("In Review").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        console.log(snapshot.val())
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, []);

  const onDelete = (contactObj, id) => {
    if (window.confirm("Are you sure that you wanted to delete that entry?")) {
      fireDb.child(`contacts/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
            toast.success("Entry Deleted Successfully");
        }
      });
    }
  };

  const updateStatus = (contactObj, id) => {
    if (window.confirm("Are you sure that you wanted to approve that marker?")) {
      fireDb.child(`messages/${id}`).update({status: "Approved"});
    }
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <h2>In Review Markers</h2>
      <table className="styled-table">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>Name</th>
            <th style={{ textAlign: "center" }}>Location</th>
            <th style={{ textAlign: "center" }}>Lat</th>
            <th style={{ textAlign: "center" }}>Lng</th>
            <th style={{ textAlign: "center" }}>Cohort</th>
            <th style={{ textAlign: "center" }}>Action</th>
            <th style={{ textAlign: "center" }}>Delete</th>
          </tr>
        </thead>
          <tbody>
            {Object.keys(data).map((id, index) => {
              return (
                <tr key={id}>
                  <td>{data[id].studentname}</td>
                  <td>{data[id].locationname}</td>
                  <td>{data[id].latitude}</td>
                  <td>{data[id].longitude}</td>
                  <td>{data[id].cohort}</td>
                  <td>
                  <button
                      className="btn btn-success"
                      onClick={() => updateStatus(data[id], id)}
                    >
                      Approve
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-delete"
                      onClick={() => onDelete(data[id], id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
      </table>

      
    </div>
  );
};

export default InReview;
