import firebase from "firebase/app";
import "firebase/database";
import 'firebase/storage';
import "firebase/auth";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID
};

const firebaseInit = firebase.initializeApp(firebaseConfig);
const fireDb = firebaseInit.database().ref();
const storage = firebase.storage();
const auth = firebase.auth();

const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    toast.error(err.message);
  }
};

export  {
  storage,
  auth, 
  logInWithEmailAndPassword,
  fireDb as default
}